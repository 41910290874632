import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { IResolution } from 'src/app/interfaces/IResolution';
import { IResponseApi } from 'src/app/interfaces/IResponseApi';

@Component({
  selector: 'app-commerce-colombia',
  templateUrl: './commerce-colombia.component.html',
  styleUrls: ['./commerce-colombia.component.scss']
})
export class CommerceColombiaComponent implements OnInit {


  public titlePage: string = 'Configuraciones > Mi perfil';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public fileName: String = 'Añadir foto de perfil';
  public srcPhoto: String = '/assets/img/photo.jfif';
  public selectedFile: File = null; 
  public photoActual: String; 
  public response = false;
  dateFormat = 'yyyy/MM/dd';
  monthFormat = 'yyyy/MM';
  formResolution: FormGroup;

  private resolutionConfig: IResolution;
  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {
    this.formResolution = this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.getProfile();
    this.getResolution();
  }

  private getResolution():void{
    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'getResolution',
      typeDoc: ''
    }
    this.api.api(data).toPromise().then((res: IResponseApi) => {
      if(res.succes === true){
        this.resolutionConfig = res.data;
        this.formResolution.get('resolutionKey').setValue(this.resolutionConfig.resolutionKey) ;
        this.formResolution.get('resolutionPrefix').setValue(this.resolutionConfig.resolutionPrefix) ;
        this.formResolution.get('resolutionNumber').setValue(this.resolutionConfig.resolutionNumber) ;
        this.formResolution.get('resolutionRangeInitial').setValue(this.resolutionConfig.resolutionRangeInitial) ;
        this.formResolution.get('resolutionRangeFinal').setValue(this.resolutionConfig.resolutionRangeFinal) ;
        this.formResolution.get('resolutionValidFrom').setValue(this.resolutionConfig.resolutionValidFrom) ;
        this.formResolution.get('resolutionValidUntil').setValue(this.resolutionConfig.resolutionValidUntil) ;

      }
    }).finally(() => { 
      $('#charging').addClass('hide');
    });
    
    data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'getSequence',
      typeDoc: 'FAC'
    }

    this.api.api(data).toPromise().then((res: IResponseApi) => {
      if(res.succes === true){
        this.formResolution.get('sequence_fac').setValue(res.data.consecutive) ;
      }
    }).finally(() => { 
      $('#charging').addClass('hide');
    });
    
    data.typeDoc = 'NCR';
    this.api.api(data).toPromise().then((res: IResponseApi) => {
      if(res.succes === true){
        this.formResolution.get('sequence_ncr').setValue(res.data.consecutive) ;
      }
    }).finally(() => { 
      $('#charging').addClass('hide');
    });

    data.typeDoc = 'NDB';
    this.api.api(data).toPromise().then((res: IResponseApi) => {
      if(res.succes === true){
        this.formResolution.get('sequence_ndb').setValue(res.data.consecutive) ;
      }
    }).finally(() => { 
      $('#charging').addClass('hide');
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {        
        this.merchant = m.data;
      }
      
    });
  }

  onSelectedFile(e){
    this.selectedFile = <File>e.target.files[0];    
    this.fileName = this.selectedFile.name; 
    
  }

  onSubmit(){

    var file = new FormData(); 

    if (this.selectedFile) {     
      file.append('image', this.selectedFile, this.selectedFile.name);
      file.append('metadata', JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails));    
      let data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'configuration/user/uploadimg/' + this.merchant + '/' + this.userDetails.id,
        type: 'post',
        data: file
      }

      this.api.api(data).toPromise().then((res: any) => {
        $('#charging').addClass('hide');
        
        this.onSubmit2();

      })
        .catch(e => {
          $('#charging').addClass('hide');
            console.log(e);
      
        });
    }else{
     
      this.onSubmit2();
    }



  }

  onSubmit2() {
    this.response = false;

    var fd;


    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
      type: 'post',
      data: fd
    }

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.response = true;
      this.getProfile();

    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status == 401) {
          console.log(e);
          // this.cookie.set('ud', '');
          // this.router.navigate(['/']);
        }
      });

  }



  getProfile(){
    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
      type: 'get-profile',
      data: ''
    }

    this.api.api(data).toPromise().then((res: any) => {

      $('#charging').addClass('hide');
      
      this.photoActual = res.image; 

      let user = JSON.parse(this.cookie.get('ud'));
      user.userDetails.image = res.image; 

      this.cookie.set('ud', JSON.stringify(user));

      if(res.is_active){
        res.is_active = '1'; 
      }
      
      this.formResolution.setValue({
        fullname: '',
        mobile: res.mobile,
        profileId: res.profileid,
        email: res.email,
        status: res.is_active,        
      });

      this.srcPhoto = res.image;

      


    })
      .catch(e => {
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  createFormGroup() {
    return new FormGroup({
      resolutionKey: new FormControl('', [Validators.required, Validators.email]),
      resolutionPrefix: new FormControl('', [Validators.required]),
      resolutionNumber: new FormControl('', [Validators.required]),
      resolutionRangeInitial: new FormControl('', [Validators.required]),
      resolutionRangeFinal: new FormControl('', [Validators.required]),
      resolutionValidFrom: new FormControl('', [Validators.required]),
      resolutionValidUntil: new FormControl('', [Validators.required]),
      sequence_fac: new FormControl('', [Validators.required]),
      sequence_ncr: new FormControl('', [Validators.required]),
      sequence_ndb: new FormControl('', [Validators.required]),
    });
  }


}
