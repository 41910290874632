import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ApiService} from 'src/app/services/api.service';
import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import * as $ from 'jquery';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {FormGroup, FormControl, Validators, FormBuilder, FormArray} from '@angular/forms';
import {IProduct} from 'src/app/interfaces/IProduct';
import {IResponseApi} from 'src/app/interfaces/IResponseApi';
import {IInvoice} from 'src/app/interfaces/IInvoice';
import {IInvoiceDetrail} from 'src/app/interfaces/IInvoiceDetrail';
import {ITaxes} from 'src/app/interfaces/ITaxes';
import {ICustomer} from 'src/app/interfaces/ICustomer';
import {ETypeDocument} from 'src/app/enums/ETypeDocument';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
    numb1: number;
    numb2: number;
    rpta: number;
    lang: any = this.translate.getDefaultLang();
    Moneda = 'Pesos'
    tarjeta = 'Contado'
    fecha = 'Dias'
    public merchantActive = JSON.parse(this.cookie.get('ud')) && JSON.parse(this.cookie.get('ud')).MerchantActive ? JSON.parse(this.cookie.get('ud')).MerchantActive : null;
    public merchant: any;
    public nameMerchant: any;
    public loadingItemSearch = false;
    public productFound: IProduct[];
    public selectedItemValue: string;
    public invoice: IInvoice;
    public formInvoice: FormGroup;
    public subTotal = 0;
    public discount = 0;
    public amout = 0;
    public tax = 0;
    public total = 0;
    public typeDocument = 'Factura de Venta';
    public typeDocumentCode = 'FAC';
    public consecutiveInvoice = 0;
    public country: any;

    isDetailExportVisible = false;
    isNewCustomerModalOpen = false;
    isInstruccionesVisible = false;
    creationResponse = 'test';
    showCompletedFieldErrorMessage = false;

    date = {
        start: new Date()
    }

    private customerSelected: ICustomer;
    private searchingItems = false;

    constructor(private api: ApiService,
                private cookie: CookieService,
                private router: Router,
                public translate: TranslateService,
                private fb: FormBuilder,
                private modalService: NzModalService,
                private _router: ActivatedRoute) {
        this.formInvoice = new FormGroup({
            CustomerDocument: new FormControl('', [Validators.required]),
            CustomerTypeDocument: new FormControl(null, [Validators.required]),
            CustomerName: new FormControl('', [Validators.required]),
            CustomerPhone: new FormControl('', [Validators.required]),
            CustomerAddres: new FormControl(''),
            fiscalRegimen: new FormControl(''),
            responsability: new FormControl(''),
            email: new FormControl('', [Validators.email]),
            depto: new FormControl('', [Validators.required]),
            region: new FormControl('', [Validators.required]),
            country: new FormControl('', [Validators.required]),
            prePhone: new FormControl(''),
            phone: new FormControl(''),
            address: new FormControl(''),
            dayCredit: new FormControl(''),
            date: new FormControl(''),
            details: this.fb.array([])
        });
    }

    get details() {
        return this.formInvoice.get('details') as FormArray;
    }

    ngOnInit() {
        this.merchant = this.merchantActive.length ? this.merchantActive[0].MerchantData.MerchantCode : null;
        this.nameMerchant = this.merchantActive.length ? this.merchantActive[0].MerchantData.BussinesName : null;
        this.country = this.merchantActive[0].CountrySupport && this.merchantActive[0].CountrySupport.length ? this.merchantActive[0].CountrySupport[0].CountryCode : 'COL';
        this.formInvoice.get('date').setValue(moment(new Date()).format('YYYY-MM-DD'));
        this.invoice = new IInvoice();
        this._router.params.subscribe(params => {
            if (params.idCustomer) {
                const data = {
                    token: JSON.parse(this.cookie.get('ud')).token,
                    service: 'getCustomer',
                    merchant: this.merchant,
                    customerid: params.idCustomer
                };

                this.api.api(data).toPromise().then((customerRes: IResponseApi) => {
                    if (customerRes.succes === true) {
                        this.customerSelected = customerRes.data;
                        this.paitDataCustomer();
                    }
                }).finally(() => {
                    $('#charging').addClass('hide');
                });
            }

            if (params.type) {
                this.typeDocumentCode = params.type;
                this.consecutiveInvoice = params.invoice;
                if (params.type === ETypeDocument.creditNote) {
                    this.typeDocument = 'Nota de Crédito'
                }
                if (params.type === ETypeDocument.debitNote) {
                    this.typeDocument = 'Nota de Debito'
                }

                let data = {
                    token: JSON.parse(this.cookie.get('ud')).token,
                    service: 'getInvoice',
                    data: {
                        type: 'FAC',
                        numberDoc: params.invoice,
                        merchant: this.merchant
                    }

                };

                this.api.api(data).toPromise().then((invoiceRes: IResponseApi) => {
                    if (invoiceRes.succes === true) {
                        this.customerSelected = invoiceRes.data.customer;
                        invoiceRes.data.details.forEach((item, index) => {
                            const formBuilderItems = this.fb.group({
                                id: new FormControl(item.itemCode, [Validators.required]),
                                detail: new FormControl(item.itemName, [Validators.required]),
                                quantity: new FormControl(item.quantity, [Validators.required]),
                                price: new FormControl(item.price, [Validators.required]),
                                subtotal: new FormControl('', [Validators.required]),
                                discount: new FormControl(''),
                                ico: new FormControl(''),
                                tax: new FormControl('19'),
                                taxAmount: new FormControl(''),
                                retencion: new FormControl(''),
                                total: new FormControl('', [Validators.required]),
                            });

                            this.details.push(formBuilderItems);
                            this.calclulateItem(index);
                            this.updateResumenTotal();
                        });

                        this.paitDataCustomer();
                    }
                }).finally(() => {
                    $('#charging').addClass('hide');
                });


            }

            if (params.typeDoc) {
                this.typeDocumentCode = 'readOnly';
                this.consecutiveInvoice = params.invoiceNumber;
                let data = {
                    token: JSON.parse(this.cookie.get('ud')).token,
                    service: 'getInvoice',
                    data: {
                        type: params.typeDoc,
                        numberDoc: params.invoiceNumber,
                        merchant: this.merchant
                    }

                };

                this.api.api(data).toPromise().then((invoiceRes: IResponseApi) => {
                    if (invoiceRes.succes === true) {
                        this.customerSelected = invoiceRes.data.customer;
                        invoiceRes.data.details.forEach((item, index) => {
                            const formBuilderItems = this.fb.group({
                                id: new FormControl(item.itemCode, [Validators.required]),
                                detail: new FormControl(item.itemName, [Validators.required]),
                                quantity: new FormControl(item.quantity, [Validators.required]),
                                price: new FormControl(item.price, [Validators.required]),
                                subtotal: new FormControl('', [Validators.required]),
                                discount: new FormControl(''),
                                ico: new FormControl(''),
                                tax: new FormControl('19'),
                                taxAmount: new FormControl(''),
                                retencion: new FormControl(''),
                                total: new FormControl('', [Validators.required]),
                            });
                            this.details.push(formBuilderItems);
                            this.calclulateItem(index);
                            this.updateResumenTotal();

                        });
                        this.formInvoice.get('date').setValue(moment(new Date(invoiceRes.data.head.doc_date)).format('YYYY-MM-DD'));
                        this.paitDataCustomer();

                    }
                }).finally(() => {
                    $('#charging').addClass('hide');
                });
            }

        });
    }


    public onSearchItem(event): void {
        let nameItem: string;
        nameItem = event;

        if (nameItem.length >= 3) {
            this.loadingItemSearch = true;
            let data = {
                token: JSON.parse(this.cookie.get('ud')).token,
                service: 'product/name',
                merchant: this.merchant,
                itemName: encodeURIComponent(nameItem)
            };

            this.api.api(data).toPromise().then((productRes: IResponseApi) => {
                $('#charging').addClass('hide');
                if (productRes.succes === true) {
                    this.productFound = productRes.data;
                }
            }).finally(() => {
                this.loadingItemSearch = false;
                $('#charging').addClass('hide');
            });
        }
    }

    public onSelectItem(event): void {
        if (!this.customerSelected) {
            this.modalService.info({
                nzTitle: 'Información',
                nzContent: '<p>Debes seleccionar un cliente o crearlo</p>',
            });
            return;
        }
        const resul = this.productFound.find(e => e.sku === event);

        const formBuilderItems = this.fb.group({
            id: new FormControl(resul.sku, [Validators.required]),
            detail: new FormControl(resul.name, [Validators.required]),
            quantity: new FormControl('1', [Validators.required]),
            price: new FormControl(resul.price1, [Validators.required]),
            subtotal: new FormControl('', [Validators.required]),
            discount: new FormControl(''),
            ico: new FormControl(''),
            tax: new FormControl('19'),
            taxAmount: new FormControl(''),
            retencion: new FormControl('0'),
            total: new FormControl('', [Validators.required]),
        });

        const subTotal = formBuilderItems.get('quantity').value * formBuilderItems.get('price').value;
        formBuilderItems.get('subtotal').setValue(subTotal);
        const taxAmout = (formBuilderItems.get('tax').value * subTotal) / 100;
        formBuilderItems.get('taxAmount').setValue(taxAmout);
        const total = (subTotal + taxAmout) - formBuilderItems.get('retencion').value;
        formBuilderItems.get('total').setValue(total);
        this.details.push(formBuilderItems);
        this.updateResumenTotal();

    }

    public addDetailItem(): void {
        if (!this.customerSelected) {
            this.modalService.info({
                nzTitle: 'Información',
                nzContent: '<p>Debes seleccionar un cliente o crearlo</p>',
            });
            return;
        }
        const formBuilderItems = this.fb.group({
            id: new FormControl('tmp001', [Validators.required]),
            detail: new FormControl('', [Validators.required]),
            quantity: new FormControl('', [Validators.required]),
            price: new FormControl('', [Validators.required]),
            subtotal: new FormControl('', [Validators.required]),
            discount: new FormControl(''),
            ico: new FormControl(''),
            tax: new FormControl('19'),
            taxAmount: new FormControl(''),
            retencion: new FormControl(''),
            total: new FormControl('', [Validators.required]),
        });

        this.details.push(formBuilderItems);
        this.updateResumenTotal();
    }

    public deleteDetailItem(index: number): void {
        this.details.removeAt(index);
        this.updateResumenTotal();
    }

    public calclulateItem(index: number): void {
        const formGroupItem = this.details.controls[index];
        const subTotal = formGroupItem.get('quantity').value * formGroupItem.get('price').value;
        formGroupItem.get('subtotal').setValue(subTotal);
        const taxAmout = (formGroupItem.get('tax').value * subTotal) / 100;
        formGroupItem.get('taxAmount').setValue(taxAmout);
        const total = (subTotal + taxAmout) - formGroupItem.get('retencion').value;
        formGroupItem.get('total').setValue(total);
        this.updateResumenTotal();
    }


    actualizar() {
        if (this.date.start) {
            // this.getData(this.date)

        } else {
            //debe completar todos los datos
        }
    }

    private paitDataCustomer(): void {
        this.formInvoice.get('CustomerDocument').setValue(this.customerSelected.identification);
        this.formInvoice.get('CustomerTypeDocument').setValue(this.customerSelected.typedoc);
        this.formInvoice.get('CustomerName').setValue(this.customerSelected.name);
        this.formInvoice.get('CustomerPhone').setValue(this.customerSelected.phone);
        this.formInvoice.get('CustomerAddres').setValue(this.customerSelected.address);
    }

    private updateResumenTotal(): void {
        this.subTotal = 0;
        this.discount = 0;
        this.tax = 0;
        this.details.controls.forEach(item => {
            this.subTotal += item.get('subtotal').value;
            this.discount += item.get('retencion').value;
            this.tax = item.get('taxAmount').value;
        });
        this.amout = this.subTotal - this.discount;
        this.total = this.tax + this.amout;
    }

    /**
     * Create Invoice
     */
    public createInvoice(): void {
        if (this.typeDocumentCode === 'readOnly') {
            return;
        }

        if (!this.customerSelected) {
            this.modalService.info({
                nzTitle: 'Información',
                nzContent: '<p>Debes seleccionar un cliente o crearlo</p>',
            });
            return;
        }

        this.invoice.country = 'COL';

        this.invoice.header = {
            consecutive: 0,
            billingCityCode: this.customerSelected.municipality,
            billingRegionCode: parseInt(this.customerSelected.department).toString(),
            code: "01",
            currencyCode: 'COP',
            currencyRate: 0,
            userid: this.merchant,
            date: moment(new Date()).format('YYYY-MM-DD'),
            emailSender: this.customerSelected.email,
            externalNumber: "",
            format: "Estandar",
            incoterms: "",
            observation: "",
            paymentMeanCode: "48",
            remark: "",
            termDay: 0,
            terms: "",
            typeOfOperation: '10',
            customerid: this.customerSelected.id.toString(),
        };

        let detail: IInvoiceDetrail [];

        this.details.controls.forEach(item => {
            const rowDetail: IInvoiceDetrail = {
                itemCode: item.get('id').value,
                itemName: item.get('detail').value,
                description: item.get('detail').value,
                price: item.get('price').value,
                quantity: item.get('quantity').value
            };

            if (item.get('tax').value > 0) {
                const tax: ITaxes = {
                    name: 'Iva',
                    code: '01',
                    type: 'P',
                    amount: item.get('tax').value
                };

                rowDetail.taxes = [tax];
            }

            if (!detail) {
                detail = [rowDetail];
            } else {
                detail.push(rowDetail);
            }

        });

        this.invoice.details = detail;

        console.log(this.invoice);

        let data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'invoce',
            data: this.invoice
        };
        if (this.typeDocumentCode === ETypeDocument.creditNote) {
            this.invoice.header.invoiceConsecutive = this.consecutiveInvoice;
            if (this.details.length != this.invoice.details.length) {
                this.invoice.header.concepto = '1';
            } else {
                this.invoice.header.concepto = '3';
            }

            data = {
                token: JSON.parse(this.cookie.get('ud')).token,
                service: 'invoce/ncr',
                data: this.invoice
            };
        }

        if (this.typeDocumentCode === ETypeDocument.debitNote) {
            this.invoice.header.invoiceConsecutive = this.consecutiveInvoice;
            if (this.details.length != this.invoice.details.length) {
                this.invoice.header.concepto = '3';
            } else {
                this.invoice.header.concepto = '4';
            }

            data = {
                token: JSON.parse(this.cookie.get('ud')).token,
                service: 'invoce/ndb',
                data: this.invoice
            };
        }


        this.api.api(data).toPromise().then((productRes: IResponseApi) => {
            $('#charging').addClass('hide');
            if (productRes.succes === true) {
                this.productFound = productRes.data;
                this.modalService.success({
                    nzTitle: 'Documento Guardado'
                });
                this.router.navigate(['conciliation/create-voucher/factura']);
            }
        }).finally(() => {
            this.loadingItemSearch = false;
            $('#charging').addClass('hide');
        });
    }


    public findCustomer(): void {
        if (this.typeDocumentCode !== ETypeDocument.invoice) {
            return;
        }

        const CustomerDocument = this.formInvoice.get('CustomerDocument').value;

        if (!CustomerDocument || CustomerDocument === '') {
            this.modalService.info({
                nzTitle: 'Información',
                nzContent: '<p>Número de documento es obligatorio</p>',
            });
            return;
        }

        let data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'getCustomerByDocument',
            merchant: this.merchant,
            document: CustomerDocument
        };

        this.api.api(data).toPromise().then((customerRes: IResponseApi) => {

            if (customerRes.succes === true) {
                this.customerSelected = customerRes.data;
                if (!this.customerSelected) {
                    this.modalService.info({
                        nzTitle: 'Información',
                        nzContent: '<p>Usuario no encontrado</p>',
                    });
                    return;
                }
                this.paitDataCustomer();
            } else {
                this.modalService.info({
                    nzTitle: 'Información',
                    nzContent: '<p>Usuario no encontrado</p>',
                });
            }
        }).finally(() => {
            this.loadingItemSearch = false;
            $('#charging').addClass('hide');
        });

    }

    public goToCustomer(): void {
        if (this.formInvoice.get('CustomerDocument').value === '') {
            this.modalService.info({
                nzTitle: 'Información',
                nzContent: '<p>Debe colocar el <b>número de documento</b> para poder crear el nuevo usuario</p>',
            });
            return;
        }
        this.router.navigate(['customers/create-customer/return/' + this.formInvoice.get('CustomerDocument').value]);
    }
}
