import {FormControl} from '@angular/forms';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from 'src/app/services/api.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import * as moment from 'moment';
import * as $ from 'jquery';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexStroke,
    ApexMarkers,
    ApexYAxis,
    ApexGrid,
    ApexTitleSubtitle,
    ApexLegend
} from 'ng-apexcharts';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    tooltip: any; // ApexTooltip;
    yaxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    @ViewChild("chart", null) chart: ChartComponent;

    // chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    series: ApexAxisChartSeries

    balance: any = '';
    currencyBalance: any = '';
    currencyBalanceInput = new FormControl('PEN');

    user: any;
    lang: any = this.translate.getDefaultLang();

    public data: any = {};
    public dataCashout: any = {};

    public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
    public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
    public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
    public convertion: any;
    merchantCashinActive: any = true;
    merchantCashoutActive: any = true;
    public countProduct = 0;
    public countCustomers = 0;
    public facDocument = 0;
    public ncrDocument = 0;
    public ndbDocument = 0;
    public dateFormat = 'yyyy/MM/dd';

    currencies: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].CurrencySupported;

    single: any[];
    view: any[] = [360, 140];
    dataChart1: any = {};
    dataChart2: any[];
    dataChart4: any[];
    dataChart5: any[];

    // options
    gradient = true;
    showLegend = true;
    showLabels = true;
    isDoughnut = false;

    styleDate = {
        "min-width": "200px",
        "padding-right": "20px"
    };


    colorScheme = {
        domain: ['#ff9100', '#072146', '#28c3d4', '#4f4f4f']
    };

    date = {
        start: new Date(),
        end: new Date()
    }

    from_date: any = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD');
    from_date_cashout: any = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD');
    to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');
    to_date_cashout: any = moment().add(1, 'days').format('YYYY-MM-DD');

    // currency_code = new FormControl(this.currencies.Cashin[0].CurrencyCode);
    currency_code = new FormControl(this.currencies.Cashin[0].CurrencyCode);


    // currency_code2 = new FormControl(this.currencies.Cashout[0].CurrencyCode);
    currency_code2 = new FormControl('PEN');
    isVisible = false;
    dataMerchant = [];

    // TRADUCCIONES CUSTOMIZADAS

    Generic = 'Genérico';
    Online = 'En linea';
    Cash = 'Efectivo';

    public balanceByBank: any[];

    constructor(
        private api: ApiService,
        private cookie: CookieService,
        private router: Router,
        public translate: TranslateService
    ) {
        this.loadDataDasboar();
    }

    ngOnInit() {

        // console.log('Lang', this.lang);

        if (this.cookie.get('ud') !== '') {
            // console.log('USER', JSON.parse(this.cookie.get('ud')));
        }

        this.validateMerchantPermissions();
        this.changeMerchant();
        this.getData();
        this.getDataCashout();
        this.translateChange();
        //this.getBalance();
        this.getBalancebyBank();

        Object.assign(this, this.dataChart2);

    }

    private loadDataDasboar(dates?: any[]): void {
        const data = {
            service: 'dasboard',
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant: this.merchant,
            params: {
                since: dates ? dates[0] : '',
                until: dates ? dates[1] : '',
            }
        };

        this.api.api(data).toPromise().then((res: any) => {
            if (res.succes === true) {
                this.chartOptions = {
                    series: [
                        {
                            name: "Facturas",
                            data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
                        },
                        {
                            name: "Nota Débito",
                            data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
                        },
                        {
                            name: "Nota Crédito",
                            data: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
                        }
                    ],
                    chart: {
                        width: 700,
                        height: 450,
                        type: "line",
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        }
                    },

                    dataLabels: {
                        enabled: false
                    },
                    tooltip: {
                        intersect: true,
                        shared: false
                    },
                    stroke: {
                        width: 3,
                        curve: "smooth"
                    },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5
                        }
                    },
                    xaxis: {
                        categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            'Oct',
                            'Nob',
                            'Dic',
                        ],

                    },
                    yaxis: {
                        min: 0,
                        max: 12.0,
                        title: {
                            text: "Engagement"
                        }
                    },
                    legend: {
                        horizontalAlign: 'center',
                        show: true,
                        position: 'right',
                        itemMargin: {
                            horizontal: 5,
                            vertical: 10,
                        },
                        containerMargin: {
                            left: 50,
                            top: 50,
                        },
                    },

                };
                this.countProduct = res.data.productCount;
                this.countCustomers = res.data.customerCount;
                this.facDocument = res.data.facDocument;
                this.ncrDocument = res.data.ncrDocument;
                this.ndbDocument = res.data.ndbDocument;
                let countFac = [], countNcr = [], countMdb = [];
                for (let index = 1; index <= 12; index++) {
                    countFac.push(0);
                    countNcr.push(0);
                    countMdb.push(0);
                    res.data.history.forEach(item => {
                        if (index === item.month) {
                            if (item.type === 'FAC') {
                                countFac[index - 1] = item.countDoc;
                            }
                            if (item.type === 'NCR') {
                                countNcr[index - 1] = item.countDoc;
                            }
                            if (item.type === 'NDB') {
                                countMdb[index - 1] = item.countDoc;
                            }
                        }
                    });
                }
                this.chartOptions.series[0].data = countFac;
                this.chartOptions.series[1].data = countMdb;
                this.chartOptions.series[2].data = countNcr;
            }

            $('#charging').addClass('hide');
            const ddd = [];
            ddd.forEach(item => {
            });

        });
    }

    validateMerchantPermissions() {
        if (this.cookie.get('ud') !== '') {
            //console.log(this.cookie.get('ud'));

            const merchantList = JSON.parse(this.cookie.get('ud')).MerchantActive;
            const selectedMerchantCode = JSON.parse(this.cookie.get('merchant'));
            let selectedItem;

            merchantList.forEach(item => {
                if (item.MerchantData.MerchantCode === selectedMerchantCode) {
                    selectedItem = item;
                }
            });

            if (selectedItem) {
                this.merchantCashinActive = selectedItem.MerchantData.ActiveCashin;
                this.merchantCashoutActive = selectedItem.MerchantData.ActiveCashout;

                (this.merchantCashinActive) ? $('#navCashin').show() : $('#navCashin').hide();
                (this.merchantCashoutActive) ? $('#navCashout').show() : $('#navCashout').hide();
            }
        }
    }

    translateChange() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (event.lang === 'en') {
                this.Generic = 'Generic';
                this.Online = 'Online';
                this.Cash = 'Cash';
            } else if (event.lang === 'es') {
                this.Generic = 'Genérico';
                this.Online = 'En linea';
                this.Cash = 'Efectivo';
            }
        });
    }

    changeMerchant() {
        this.api.merchant$.subscribe(m => {
            if (m.event === 'change-merchant') {
                this.validateMerchantPermissions();
                this.merchant = m.data;
                this.currencies = m.dataMerchant;
                this.getCurrenciesByIdMerchant(m.data, m.dataMerchant);
                this.getData();
                this.getDataCashout();
            }
        });
    }

    getCurrenciesByIdMerchant(id, data) {
        const i = 0;
        let currencySupported;
        data.forEach(e => {
            if (e.MerchantData.MerchantCode === id) {
                this.currencies = e.CurrencySupported;
            }
        });
    }

    getBalance() {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'balance/' + this.merchant + '/' + this.currencyBalanceInput.value,
        };

        $('#charging').removeClass('hide');

        this.api.api(data).toPromise().then((res: any) => {
            $('#charging').addClass('hide');
            this.balance = res.Balance;
            this.currencyBalance = res.Currency;
        })
            .catch(e => {
                $('#charging').addClass('hide');
                if (e.status === 401 || e.status === 0) {
                    this.cookie.set('ud', '');
                    this.router.navigate(['/']);
                }
            });
    }

    private getBalancebyBank(): void {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'balancebyBanck',
            data: this.merchant
        };

        $('#charging').removeClass('hide');

        this.api.api(data).toPromise().then((res: any) => {
            $('#charging').addClass('hide');
            this.balanceByBank = res.data;
        });
    }

  getData() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'dasboard/cashin/',
      data: {
        from_date: moment(this.from_date).format("YYYY-MM-DD"),
        to_date: moment(this.to_date).format("YYYY-MM-DD"),
        currency_code: this.currency_code.value,
      }
    };

        $('#charging').removeClass('hide');

        this.api.api(data).toPromise().then((res: any) => {

            $('#charging').addClass('hide');

            // this.merchantCashinActive = true;
            // $('#navCashin').show();

            this.data = res;
            let dataRes = [];
            dataRes.push({
                "name": 'En linea',
                "value": parseInt(res.graphic2.percentage_online) / 100 * parseInt(res.graphic2.total_amount)
            });
            dataRes.push({
                "name": 'Efectivo',
                "value": parseInt(res.graphic2.percentage_cash) / 100 * parseInt(res.graphic2.total_amount)
            });

            this.dataChart2 = dataRes;
            this.convertion = res.graphic3.Convertion;
            this.dataChart4 = res.graphic4;
            this.dataChart5 = res.graphic5;

        })
            .catch(e => {
                $('#charging').addClass('hide');
                if (e.status === 401 || e.status === 0) {
                    this.cookie.set('ud', '');
                    this.router.navigate(['/']);
                }
            });

        this.api.api(data).toPromise().then((result: any) => {
            this.dataChart1 = result.graphic1;
            this.dataChart2 = result.graphic2;
        }).catch(error => {
            if (error.status === 401) {
                sessionStorage.setItem('ud', '');
                this.router.navigate(['/']);
            }
        });
    }

  getDataCashout() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'dasboard/cashout/',
      data: {
        from_date: moment(this.from_date_cashout).format("YYYY-MM-DD"),
        to_date: moment(this.to_date_cashout).format("YYYY-MM-DD"),
        currency_code: this.currency_code.value,
      }
    };

        $('#charging').removeClass('hide');

        this.api.api(data).toPromise().then((res: any) => {
            $('#charging').addClass('hide');
            // this.merchantCashoutActive = true;
            // $('#navCashout').show();
            this.dataCashout = res;
        })
            .catch(e => {
                $('#charging').addClass('hide');
                if (e.status === 401 || e.status === 0) {
                    this.cookie.set('ud', '');
                    this.router.navigate(['/']);
                }
            });
    }

    onSelect(data): void {
        console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    toogleDetail(e) {
        $('#detail_' + e).toggleClass('hide');
    }

    toogleDetail2(e) {
        $('#detail2_' + e).toggleClass('hide');
    }

    onOk(value: any[]) {
        if (value.length) {
            const dates = [
                moment(value[0]).format('YYYY-MM-DD'),
                moment(value[1]).format('YYYY-MM-DD')
            ];
            this.loadDataDasboar(dates);
        } else {
            this.loadDataDasboar();
        }
    }
}
