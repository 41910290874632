import { AuthService } from '../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    response: any = false;
    public isLogged = false;
    formLogin: FormGroup;
    validateCaptcha = false;
    lang = 'es';
    isLoading = false;
    ////////////////////
    public dataLogin: string;
    public merchant: string;

    constructor(private aR: ActivatedRoute, private router: Router, private cookie: CookieService, private authService: AuthService, public translate: TranslateService) {
        this.formLogin = this.createFormGroup();

        if (this.aR.snapshot.paramMap.get('data')) {
            this.dataLogin = this.aR.snapshot.paramMap.get('data').replace(/(kxzy!)/g, '/');
        }
        this.merchant = this.aR.snapshot.paramMap.get('merchant');
    }

    createFormGroup() {
        return new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        });
    }

    onLogin(): void {

        this.isLoading = true;
        if (this.validateCaptcha) {

            const data = {
                email: this.formLogin.get('email').value,
                password: this.formLogin.get('password').value,
                service: 'auth/login'
            };

            this.authService.api(data).subscribe((result: any) => {
                    if (result.success !== 'Error en credenciales') {
                        this.isLoading = false;
                        this.cookie.set('ud', JSON.stringify(result));
                        this.cookie.set('merchant', result.MerchantActive[0].MerchantData.MerchantCode);
                        this.router.navigate(['/']);
                    } else {
                        this.isLoading = false;
                        this.response = 1;
                        // 'Error en credenciales';
                    }
                },
                error => {
                    console.log(error);
                    this.isLoading = false;
                    if (error.status === 401) {
                        this.response = 2;
                        // 'Error en el correo o contraseña'
                    }
                });
        } else {
            this.isLoading = false;
            this.response = 3; // 'Valide el recaptcha por favor'
        }

    }


    ngOnInit() {
        this.verifySession();
    }

    verifySession() {
        if (this.cookie.get('ud') !== '') {
            this.isLogged = true;
            this.router.navigate(['/']);
        } else {
            if (this.dataLogin && this.merchant) {
                this.cookie.set('ud', this.dataLogin);
                this.cookie.set('merchant', this.merchant);
                this.isLogged = true;
                this.router.navigate(['/']);
            }
        }
    }

    onResetForm() {
        this.formLogin.reset();
    }

    seePassword(e) {
        if ($('#password').attr('type') === 'password') {
            $('#password').attr('type', 'text');
        } else {
            $('#password').attr('type', 'password');
        }
    }


    resolved(captchaResponse: string) {
        if (captchaResponse) {
            this.validateCaptcha = true;
        }
    }


    changeLang(language) {
        this.lang = language;
        this.translate.use(language);
    }

    get email() {
        return this.formLogin.get('email');
    }

    get password() {
        return this.formLogin.get('password');
    }

}
