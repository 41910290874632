import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-debito',
  templateUrl: './create-debito.component.html',
  styleUrls: ['./create-debito.component.scss']
})
export class CreateDebitoComponent implements OnInit {
  numb1: number;
  numb2 : number;
  rpta : number;
  lang: any = this.translate.getDefaultLang();

  isDetailExportVisible = false;
  isNewCustomerModalOpen = false;
  formCustomer: FormGroup;
  isInstruccionesVisible = false;
  creationResponse = 'test';
  showCompletedFieldErrorMessage = false;

  date = {
    start: new Date()
  }

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {

  }

  ngOnInit() {
  }

  actualizar(){
    if(this.date.start){
      // this.getData(this.date)
      
    }else{
      //debe completar todos los datos
    }
  }
}