import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  // urlCore1 = 'https://dev-api-console-weinvoice.wepay4u.com/';
  // urlCore = 'https://dev-api-cms-merchant.wepay4u.com/api/v1/';

  urlCore1 =  environment.api_url_invoice;
  urlCore =  environment.api_url_cachin;


  merchant$ = new EventEmitter<Object>();
  constructor(private http: HttpClient, private cookie: CookieService, public router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf('auth') === -1) {
      let idToken = '';
      if (this.cookie.get('ud') && this.cookie.get('ud') !== '') {
        idToken = JSON.parse(this.cookie.get('ud')).token;
      }

      if (idToken) {
        const cloned = req.clone({
          headers: req.headers
            .set("Authorization", "Bearer " + idToken)
        });
        return next.handle(cloned);
      } else {
        this.router.navigate(['login']);
        return next.handle(req);
      }

    } else {
      return next.handle(req);
    }
  }


  api(datos) {

    if(datos.service.indexOf('balance')===-1){
      $('#charging').removeClass('hide');
    }

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'application/json')
    }

    const headerDownload = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'application/json')
        .set('responseType', 'blob')
    };

    const headerFile = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', 'multipart/form-data')
        .set('Accept', 'application/json')
    };


    const headerUpload = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${datos.token}`)
        .set('Content-Type', null)
        .set('Accept', "multipart/form-data")
    };

    switch (datos.service) {

      case 'cashin/transactions/':
        console.log('DATOS filter', datos.data)
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'cashin/transactions/customer':
        return this.http.get(`${this.urlCore + 'cashin/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashin/transactions/post':
        return this.http.post(`${this.urlCore + 'cashin/transactions'}`, datos.data);
      case 'cashin/transactions/WithParams':
        return this.http.get(`${this.urlCore + 'cashin/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashin/transactions/download/':
        window.location.href = this.urlCore + 'cashin/transactions/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'cashin/transactions/confirm_recharge':
          return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'conciliation/':
        console.log('DATOS CONCILIATION', datos.data)
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + this.param(datos.data) }`);
      case 'conciliation/requestmoney':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
        break;
      case 'settlement/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant}`, header);
      case 'settlement/WithParams':
        return this.http.get(`${this.urlCore + 'settlement/' + datos.merchant + this.param(datos.data)}`, header);
      case 'settlement/download/':
        window.location.href = this.urlCore + 'settlement/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'settlement/detail/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + '/' + datos.data}`, header);
      case 'cashin/transactions/detail/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + '/' + datos.data}`, header);
      case 'cashout/transactions/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + this.param(datos.data)}`, header);
        case 'cashout/transactions/customer':
          return this.http.get(`${this.urlCore + 'cashout/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashout/transactions/WithParams':
        return this.http.get(`${this.urlCore + 'cashout/transactions/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashout/transactions/detail/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + '/' + datos.data}`, header);
      case 'cashout/transactions/batchfile':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'cashin/transactions/batchfile':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'cashout/transactions/download/':
        window.location.href = this.urlCore + 'cashout/transactions/download/' + datos.merchant + this.param(datos.data);
        break;
      case 'dasboard/cashin/':
      return this.http.get(`${this.urlCore + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'dasboard/cashout/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'bankAccount/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant}`, header);
      case 'cashout/payroll':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'cashout/payroll':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'cashout/payroll/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant}`, header);
      case 'cashout/payroll/WithParams':
        return this.http.get(`${this.urlCore + 'cashout/payroll/' + datos.merchant + this.param(datos.data)}`, header);
      case 'cashout/payroll/put':
        console.log('cashout/payroll/put')
        return this.http.put(`${this.urlCore + 'cashout/payroll/' + datos.merchant + "/" +  datos.id }`, datos.data);
      case 'cashout/payroll/detail/':
        return this.http.get(`${this.urlCore + 'cashout/payroll/' + datos.merchant + "/" + datos.data}`);


      case 'customer/':
        return this.http.get(`${this.urlCore + datos.service + datos.merchant + this.param(datos.data)}`, header);
      case 'customer/create/':
        return this.http.post(`${this.urlCore + 'customer'}`, datos.data);
      case 'customer/download/':
        window.location.href = this.urlCore + 'customer/download/' + datos.merchant + this.param(datos.data);
      case 'customer/batchfile':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);


      case 'listAllOperationCashin':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'listAllOperationCashOut':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'auth/login':
        return this.http.post(`${this.urlCore + datos.service}`, datos);
      case 'auth/logout':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'listMerchant':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'listAllOperationCashinWithParams':
        return this.http.get(`${this.urlCore + 'listAllOperationCashin' + this.param(datos.data)}`, header);
      case 'listAllOperationCashOutWithParams':
        return this.http.get(`${this.urlCore + 'listAllOperationCashOut' + this.param(datos.data)}`, header);
      case 'listBanksCashout':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'operationDetail':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'loperationDetailCashout':
        return this.http.get(`${this.urlCore + datos.service + '/' + datos.data}`, header);
      case 'forcePayment/listOperation':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'forcePayment/listOperationWithParams':
        return this.http.get(`${this.urlCore + 'forcePayment/listOperation' + this.param(datos.data)}`, header);
      case 'listBanksCashin':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'forcePayment':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data, header);
      case 'notification/listOperation':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'notification/listOperationWithParams':
        return this.http.get(`${this.urlCore + 'notification/listOperation' + this.param(datos.data)}`, header);
      case 'settlement/list':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'settlement/listWithParams':
        return this.http.get(`${this.urlCore + 'settlement/list' + this.param(datos.data)}`, header);
      case 'balance/listBalance':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'balance/listBalanceWithParams':
        return this.http.get(`${this.urlCore + 'balance/listBalance' + this.param(datos.data)}`, header);
      case 'notification/execute/':
        return this.http.get(`${this.urlCore + datos.service + datos.data}`, header);

      case 'balance/create':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'payroll/listBanks':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'payroll/create':
        return this.http.get(`${this.urlCore + datos.service}`, header);
      case 'listAllOperationCashin/download':
        window.location.href = this.urlCore + 'listAllOperationCashin/download' + this.param(datos.data);
        break;
      case 'settlement/download':
        window.location.href = this.urlCore + 'settlement/download' + this.param(datos.data);
        break;
      case 'listAllOperationCashOut/download':
        window.location.href = this.urlCore + 'listAllOperationCashOut/download' + this.param(datos.data);
        break;
      case 'configuration/user/change_password':
        return this.http.post(`${this.urlCore + datos.service}`, datos.data);
      case 'balancebyBanck':
        return this.http.get(`${this.urlCore + 'balancebybank/' + datos.data}`, header);

      /**
       * Api invoice
       */
      case 'customer/all':
        return this.http.get(this.urlCore1 + datos.service + '/' + datos.merchant + '/' + datos.page, header);
      case 'customer':
        return this.http.post(this.urlCore1 + datos.service, datos.data, header);
      case 'getCustomerByDocument':
        return this.http.get(this.urlCore1 + 'customer/document/' + datos.merchant + '/' + datos.document, header);
      case 'getCustomer':
        return this.http.get(this.urlCore1  + 'customer/' + datos.merchant + '/' + datos.customerid, header);
      case 'getdpto':
        return this.http.get('https://api-cms-admin.wepay4u.com/api/v1/countries/3?country_code=' + datos.country_code, header );
      case 'getMunicipality':
        return this.http.get('https://api-cms-admin.wepay4u.com/api/v1/municipalities?state_id=' + datos.municipality, header );
      case 'product/all':
        return this.http.get(this.urlCore1 + datos.service + '/' + datos.merchant + '/' + datos.page, header);
      case 'getproduct':
        return this.http.get(this.urlCore1 +  'product/' + datos.merchant + '/' + datos.productid, header);
      case 'product':
        return this.http.post(this.urlCore1 + datos.service, datos.data, header);
      case 'product/name':
        return this.http.get(this.urlCore1 +  datos.service + '/' + datos.merchant + '/' + datos.itemName, header);
      case 'invoce/date':
        return this.http.get(this.urlCore1 + datos.service + '/' + datos.data.from_date + '/' + datos.data.to_date + '/'  + datos.page + this.param(datos.params), header);
      case 'invoce':
        return this.http.post(this.urlCore1 + datos.service, datos.data, header);
      case 'invoce/ncr':
          return this.http.post(this.urlCore1 + datos.service, datos.data, header);
      case 'invoce/ndb':
        return this.http.post(this.urlCore1 + datos.service, datos.data, header);
      case 'getInvoice':
        return this.http.get(this.urlCore1 + 'invoce/' + datos.data.type + '/' + datos.data.numberDoc + '/'  + datos.data.merchant, header);
      case 'export/invoice':
        window.location.href = this.urlCore1 + 'invoce/download/' + datos.data.from_date + '/' + datos.data.to_date + '/'  + datos.merchant;  
      case 'dasboard':
        return this.http.get(this.urlCore1 + 'dasboard/' + datos.merchant + this.param(datos.params), header);
      case 'getResolution':
        return this.http.get(this.urlCore1 + 'resolution/'  + datos.merchant, header);
      case 'getSequence':
        return this.http.get(this.urlCore1 + 'Sequence/'  + datos.typeDoc + '/' + datos.merchant, header);
      default:

        if (datos.service.indexOf('balance') > -1) {
          return this.http.get(`${this.urlCore + datos.service}`, header);
        }

        if (datos.service.indexOf('configuration/user') > -1) {
          switch (datos.type) {
            case 'get-users':
              return this.http.get(`${this.urlCore + datos.service + datos.merchant}`, header);
            case 'get-users-with-params':
              return this.http.get(`${this.urlCore + 'configuration/user/' + datos.merchant + this.param(datos.data)}`, header);
            case 'post':
              return this.http.post(`${this.urlCore + datos.service}`, datos.data);
            case 'get-profile':
              return this.http.get(`${this.urlCore + datos.service}`, header);
            default:
            break;
          }
        }


        if(datos.service.indexOf('configuration/secretkey/') > -1){
          if (datos.data.type == 'update'){

            console.log('datossekec', datos.data.data)

            return this.http.put(`${this.urlCore + datos.service}`, datos.data.data);
          }else{
            return this.http.get(`${this.urlCore + datos.service}`, header);
          }

        }

        if (datos.service.indexOf('configuration/webhook/') > -1) {

          if (datos.data.type == 'update'){
            return this.http.put(`${this.urlCore + datos.service}`, datos.data);
          }else{
            return this.http.get(`${this.urlCore + datos.service}`, header);
          }

        }

      break;

    }

  }

  param(object){
      var parameters = [];
      for (var property in object) {
          if (object.hasOwnProperty(property)) {
              if(object[property] != '' && property != 'period'){
                parameters.push(encodeURI(property + '=' + object[property]));
              }
          }
      }
      return '?'+parameters.join('&');
  }



  getCustomerType(){
    return [
      {
        name: 'Shopper',
        value: '1',
      },
      {
        name: 'Agente',
        value: '2',
      }
    ];

  }

  getStatus(){
    return [
      {
        name: 'Expirado',
        value: 'o',
      },
      {
        name: 'Pendiente',
        value: '10',
      },
      {
        name: 'Anulado',
        value: '11',
      },
      {
        name: 'Pagado',
        value: '12',
      },
      {
        name: 'Notificado',
        value: '14',
      },
      {
        name: 'Liquidado',
        value: '16',
      },
      {
        name: 'Conciliado',
        value: '13',
      },
      {
        name: 'Error Notificación',
        value: '15',
      }
    ];
  }

  getStatusCashOutOperations() {
    return [
      {
        name: 'Anulado',
        value: '0',
      },
      {
        name: 'Creado',
        value: '10',
      },
      {
        name: 'Aprobación del cliente',
        value: '11',
      },
      {
        name: 'En proceso (Payroll creado)',
        value: '12',
      },
      {
        name: 'Rechazado por el banco',
        value: '13',
      },
      {
        name: 'Trasferencia completa',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '15',
      },
      {
        name: 'Error en notificar',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      }
    ];
  }

  getStatusCashInSttelement(){
    return [
      {
        name: 'Anulado',
        value: '0'
      },
      {
        name: 'Creado',
        value: '1'
      },
      {
        name: 'Pagado',
        value: '2'
      },
      {
        name: 'Notificada',
        value: '3'
      },
    ];
  }


  getStatusActive(){
    return [
      {
        name: 'Activo',
        value: '1'
      },
      {
        name: 'Inactivo',
        value: '2'
      }
    ];
  }


  getStatusCashOut() {
    return [
      {
        name: 'Anulado',
        value: 'o',
      },
      {
        name: 'Creado',
        value: '10',
      },
      {
        name: 'Aprobación de cliente',
        value: '11',
      },
      {
        name: 'En proceso (Payroll)',
        value: '12',
      },
      {
        name: 'Error en procesar',
        value: '13',
      },
      {
        name: 'Procesado',
        value: '14',
      },
      {
        name: 'Notificado',
        value: '15',
      },
      {
        name: 'Error al notificar',
        value: '16',
      },
      {
        name: 'Reprocesar',
        value: '17',
      }
    ];
  }

  getStatusPayroll(){
    return [
      {
        name: 'Creado',
        value: '1',
      },
      {
        name: 'Aprobado',
        value: '2',
      },
      {
        name: 'Procesado',
        value: '3',
      }
    ];
  }

  getStatusByIDPayroll(id) {

    id = id.toString();

    switch (id) {
      case '1':
        return 'Creado';
      case '2':
        return 'Aprobado';
      case '3':
        return 'Procesado';
        default:
          return id;
    }

  }


  getStatusByID(id) {

    id = id.toString();

    switch (id) {
      case 'o':
        return 'Expirado';
      case '10':
        return 'Pendiente';
      case '11':
        return 'Anulado';
      case '12':
        return 'Pagado';
      case '14':
        return 'Notificado';
      case '16':
        return 'Liquidado';
      case '13':
        return 'Conciliado';
      case '15':
        return 'Error Notificación';
      case '18':
          return 'Trasferencia Completa';
      default:
        return 'Expirado';
    }

  }

  getStatusByIDCashInSttelement(id){
    id = id.toString();
    switch (id) {
      case '0':
        return 'Anulado';
      case '1':
        return 'Creado';
      case '2':
        return 'Pagado';
      case '3':
        return 'Notificado';
      default:
        return 'Desconocido';
    }
  }

  getStatusByIDCashoutOperations(id){
    id = id.toString();
    switch (id) {
      case '0':
        return 'Anulado';
      case '10':
        return 'Creado';
      case '11':
        return 'Aprobación de cliente';
      case '12':
        return 'En proceso (Payroll creado)';
      case '13':
        return 'Rechazado por el banco';
      case '14':
        return 'Trasferencia completa';
      case '15':
        return 'Notificado';
      case '16':
        return 'Error en notificar';
      case '17':
        return 'Reprocesar';
      default:
        return 'Desconocido';
    }
  }


  getStatusByIDCashOut(id) {

    id=id.toString();

    switch (id) {
      case 'o':
        return 'Anulado';
      case '10':
        return 'Creado';
      case '11':
        return 'Aprobación de cliente';
      case '12':
        return 'En proceso (Payroll)';
      case '13':
        return 'Rechazado por el banco';
      case '14':
        return 'Trasferencia completa';
      case '15':
        return 'Notificado';
      case '16':
        return 'Error al notificar';
      case '17':
        return 'Reprocesar';
      default:
      return 'Expirado';
    }
  }

  validateMerchantPermissions() {
    if (this.cookie.get('ud') !== '') {
      const merchantList = JSON.parse(this.cookie.get('ud')).MerchantActive;
      const selectedMerchantCode = JSON.parse(this.cookie.get('merchant'));
      let selectedItem;

      merchantList.forEach(item => {
        if (item.MerchantData.MerchantCode === selectedMerchantCode) {
          selectedItem = item;
        }
      });

      if (selectedItem) {
        (selectedItem.MerchantData.ActiveCashin) ? $('#navCashin').show() : $('#navCashin').hide();
        (selectedItem.MerchantData.ActiveCashout) ? $('#navCashout').show() : $('#navCashout').hide();
      }
    }
  }


}
