import {ApiService} from 'src/app/services/api.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    urlredirectweinvoice = environment.urlredirectweinvoice; // invoice
    urlredirectwepay = environment.urlredirectwepay; // console merchant
    urlredirectwecourier = environment.urlredirectwecourier; // wecourier

    urlredirectmiapp = 'http://localhost:4200/login';
    urlredirectwetech = 'http://localhost:4200/login';

    // urlredirectwepay = 'https://dev-console-merchant.wepay4u.com/login';
    // urlredirectweinvoice = 'https://dev-console-merchant.wepay4u.com/login'
    // urlredirectwecourier = 'https://dev-console-merchant.wepay4u.com/login'
    // urlredirectwetech = 'https://dev-console-merchant.wepay4u.com/login'

    @Output() dataOutput = new EventEmitter<any>();

    public userDetails: any;
    public MerchantActive: any;
    public merchant: any;
    public isLogged: any;
    lang: any = 'es';
    langSelect = '';
    merchant2: string;
    dataUser: string;
    merchantData = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData;

    constructor(public cookie: CookieService, public router: Router, public api: ApiService, public translate: TranslateService) {
///////////////////
        this.merchant2 = this.cookie.get('merchant')
        this.dataUser = this.cookie.get('ud')
        /////
        if (this.cookie.get('ud') && this.cookie.get('ud') !== '') {
            this.userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
            this.MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;

            if (!this.MerchantActive) {
                this.cookie.set('ud', '');
                this.router.navigate['/login'];
            } else {
                // this.merchant = new FormControl(JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode);
                this.merchant = new FormControl(JSON.parse(this.cookie.get('merchant')));
            }

        }

    }

    ngOnInit() {
        this.verifySession();
        this.translateChange();

        if (this.translate.currentLang !== undefined) {
            this.langSelect = this.translate.currentLang;
            this.lang = this.translate.currentLang;
        }
    }

    translateChange() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (event.lang === 'en') {
                this.langSelect = 'en';
            } else if (event.lang === 'es') {
                this.langSelect = 'es';
            }
        });
    }

    onClickMenu(e) {
        $('#nav').show();
        $('#menu2').hide();
    }

    changeMerchant() {
        if (this.merchant) {
            this.cookie.set('merchant', this.merchant.value);
        }
        this.api.validateMerchantPermissions();
        this.api.merchant$.emit({
            event: 'change-merchant',
            data: this.merchant.value,
            dataMerchant: this.MerchantActive
        });
    }

    verifySession() {
        if (this.cookie.get('ud') === '') {
            this.isLogged = true;
            this.router.navigate(['/login']);
        }
    }

    changeLang(language) {
        this.lang = language;
        this.translate.use(language);

        if (language === 'es') {
            $('#wepay4u > div.der.d-flex.flex-column.bg-color4 > div > app-dashboard > div > div.mt-1.card-dash.bg-white.p-5.shadow.ng-star-inserted > div.section2.data.mt-4.row > div:nth-child(1) > div > ngx-charts-advanced-pie-chart > div > div.advanced-pie-legend-wrapper > ngx-charts-advanced-legend > div > div.legend-items-container > div > div:nth-child(1) > div.item-label').text('En linea');
        }
        if (language === 'en') {
            $('#wepay4u > div.der.d-flex.flex-column.bg-color4 > div > app-dashboard > div > div.mt-1.card-dash.bg-white.p-5.shadow.ng-star-inserted > div.section2.data.mt-4.row > div:nth-child(1) > div > ngx-charts-advanced-pie-chart > div > div.advanced-pie-legend-wrapper > ngx-charts-advanced-legend > div > div.legend-items-container > div > div:nth-child(1) > div.item-label').text('Online');
        }

        if (language === 'es') {
            $('#wepay4u > div.der.d-flex.flex-column.bg-color4 > div > app-dashboard > div > div.mt-1.card-dash.bg-white.p-5.shadow.ng-star-inserted > div.section2.data.mt-4.row > div:nth-child(1) > div > ngx-charts-advanced-pie-chart > div > div.advanced-pie-legend-wrapper > ngx-charts-advanced-legend > div > div.legend-items-container > div > div:nth-child(2) > div.item-label').text('Efectivo');
        }
        if (language === 'en') {
            $('#wepay4u > div.der.d-flex.flex-column.bg-color4 > div > app-dashboard > div > div.mt-1.card-dash.bg-white.p-5.shadow.ng-star-inserted > div.section2.data.mt-4.row > div:nth-child(1) > div > ngx-charts-advanced-pie-chart > div > div.advanced-pie-legend-wrapper > ngx-charts-advanced-legend > div > div.legend-items-container > div > div:nth-child(2) > div.item-label').text('Cash');
        }
    }

    urlBackoffice($event) {
        const redirectUrl = `${$event.target.value}/${this.merchant2}/${this.dataUser.replace(/\//g, 'kxzy!')}`
        console.log('urlEvent', redirectUrl)
        window.location.href = redirectUrl
    }

    urlredirectWepay() {
        const redirectUrl = `${this.urlredirectwepay}/${this.merchant2}/${this.dataUser.replace(/\//g, 'kxzy!')}`
        window.location.href = redirectUrl
    }

    urlredirectMiapp() {
        const redirectUrl = `${this.urlredirectmiapp}/${this.merchant2}/${this.dataUser.replace(/\//g, 'kxzy!')}`
        window.location.href = redirectUrl
    }

    urlredirectWeinvoice() {
        const redirectUrl = `${this.urlredirectweinvoice}/${this.merchant2}/${this.dataUser.replace(/\//g, 'kxzy!')}`
        window.location.href = redirectUrl
    }

    urlredirectWecourier() {
        const redirectUrl = `${this.urlredirectwecourier}/${this.merchant2}/${this.dataUser.replace(/\//g, 'kxzy!')}`
        window.location.href = redirectUrl
    }

    urlredirectWetech() {
        const redirectUrl = `${this.urlredirectwetech}/${this.merchant2}/${this.dataUser}`
        window.location.href = redirectUrl
    }
}
