import {Component, OnInit} from '@angular/core';
import {ApiService} from 'src/app/services/api.service';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import * as $ from 'jquery';
import {ICustomer} from 'src/app/interfaces/ICustomer';
import {IResponseApi} from 'src/app/interfaces/IResponseApi';
import {IDepartaments} from 'src/app/interfaces/IDepartaments';
import {IMunicipalities} from 'src/app/interfaces/IMunicipalities';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-create-customer',
    templateUrl: './create-customer.component.html',
    styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

    demoValue = 3;
    public titlePage: string = 'Clientes > Nuevo Cliente';
    public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
    public merchantActive = JSON.parse(this.cookie.get('ud')) && JSON.parse(this.cookie.get('ud')).MerchantActive ? JSON.parse(this.cookie.get('ud')).MerchantActive : null;
    public merchant: any;
    public fileName = 'Añadir foto de perfil';
    public srcPhoto = '/assets/img/photo.jfif';
    public selectedFile: File = null;
    public photoActual: string;
    public response = false;
    public departaments: IDepartaments[];
    public municipalities: IMunicipalities[];
    public computeAddress = 'No hay Drección Disponible';
    public country: any;

    private customerData: ICustomer;
    private returnToInvoice = false;
    formProfile: FormGroup;

    constructor(private api: ApiService, private cookie: CookieService, private router: Router, private _router: ActivatedRoute, private modalService: NzModalService) {
        this.formProfile = this.createFormGroup();
    }

    async ngOnInit() {
        this.merchant = this.merchantActive.length ? this.merchantActive[0].MerchantData.MerchantCode : null;
        this.country = this.merchantActive[0].CountrySupport && this.merchantActive[0].CountrySupport.length ? this.merchantActive[0].CountrySupport[0].CountryCode : 'COL';
        this.changeMerchant();
        this.getProfile();
        //await this.getDepartament('COL');
        this._router.params.subscribe(params => {

            if (params.id) {

                const data = {
                    token: JSON.parse(this.cookie.get('ud')).token,
                    service: 'getCustomer',
                    merchant: this.merchant,
                    customerid: params.id
                };

                this.api.api(data).toPromise().then(async (customerRes: IResponseApi) => {
                    if (customerRes.succes === true) {

                        this.customerData = customerRes.data;
                        await this.getDepartament(this.customerData.country);
                        const dpto = this.departaments.find(x => x.StateCode == this.customerData.department).StateID;
                        
                        if (!dpto) {
                            this.modalService.error({
                                nzTitle: 'Error',
                                nzContent: '<p>Hubo un problema con el cliente</p>',
                                nzOnOk: () => console.log('Error')
                            });
                           
                        }
                       
                        this.formProfile.get('typeDoc').setValue(this.customerData.typedoc);
                        this.formProfile.get('numberDoc').setValue(this.customerData.identification);
                        this.formProfile.get('typeCustomer').setValue(this.customerData.persontype);
                        this.formProfile.get('fullname').setValue(this.customerData.name);
                        this.formProfile.get('fiscalRegimen').setValue(this.customerData.tax_regime);
                        this.formProfile.get('responsability').setValue(this.customerData.fiscal_responsibility);
                        this.formProfile.get('email').setValue(this.customerData.email);
                        this.formProfile.get('depto').setValue(dpto.toString());
                        this.formProfile.get('region').setValue(this.customerData.municipality);
                        this.formProfile.get('country').setValue(this.customerData.country);
                        this.formProfile.get('prePhone').setValue(this.customerData.phone.slice(0, 2));
                        this.formProfile.get('phone').setValue(this.customerData.phone.slice(3));
                        this.formProfile.get('address').setValue(this.customerData.address);
                        this.formProfile.get('dayCredit').setValue(this.customerData.term_counting);
                        this.formProfile.get('zipcode').setValue(this.customerData.zip_code);
                        this.formProfile.get('via').setValue(this.customerData.via_type);
                        this.formProfile.get('viaDetails').setValue(this.customerData.via);
                        this.formProfile.get('numberBuilder').setValue(this.customerData.number_address);
                        this.formProfile.get('builder').setValue(this.customerData.inmueble_type);
                        this.formProfile.get('builderDetails').setValue(this.customerData.inmueble);
                        this.formProfile.get('floor').setValue(this.customerData.floor);

                        await this.getMunicipality(this.formProfile.get('depto').value);

                        this.computeAddress = this.getComputeAddress(this.customerData.country, this.formProfile.get('depto').value, this.customerData.municipality);
                    }else{
                        await this.getDepartament('COL');
                    }
                }).finally(() => {
                    $('#charging').addClass('hide');
                });
            }

            if (params.document) {
                this.formProfile.get('numberDoc').setValue(params.document);
                this.returnToInvoice = true;
            }
        });
    }

    public updateComputeAddress(event): void {
        this.computeAddress = this.getComputeAddress(this.formProfile.get('country').value, this.formProfile.get('depto').value, this.formProfile.get('region').value);
    }

    private getComputeAddress(country: string, depto: number, region: string): string {

        let nameContry = 'Peru';
        if (this.formProfile.get('country').value == country) {
            nameContry = 'Colombia';
        }

        if (!this.departaments || !this.municipalities) {
            return '';
        }
        const nameDepartemanet = this.departaments.find(i => i.StateID == depto);
        const nameRegion = this.municipalities.find(i => i.MunicipalityCode == region);

        return this.formProfile.get('typeDoc').value + ' ' + this.formProfile.get('numberDoc').value + ' ' +
            this.formProfile.get('fullname').value + ', ' + nameContry + ', ' + nameDepartemanet.StateName + ' - ' +
            nameRegion.MunicipalityName + ' - C.P ' + this.formProfile.get('zipcode').value + ' - ' +
            this.formProfile.get('via').value + ' ' +
            this.formProfile.get('viaDetails').value + ' ' +
            this.formProfile.get('numberBuilder').value + ' ' +
            this.formProfile.get('builder').value + ' ' +
            this.formProfile.get('builderDetails').value + ' ' +
            this.formProfile.get('floor').value
            + ' // ' + this.formProfile.get('prePhone').value + ' ' + this.formProfile.get('phone').value +
            ' - ' + this.formProfile.get('email').value + ' ' + this.formProfile.get('address').value;
    }

    private async getDepartament(CountryCode: string): Promise<void> {
        let data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'getdpto',
            country_code: CountryCode
        };

        await this.api.api(data).toPromise().then((res: { data: any[], message: string, status: boolean }) => {
            if (res.status) {
                this.departaments = res.data[0].States;
                this.departaments.sort((a, b) => (a.StateName > b.StateName) ? 1 : -1)
            }
        }).finally(() => {
            $('#charging').addClass('hide');
        });
    }

    private async getMunicipality(dptoCode: string): Promise<void> {
        let data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'getMunicipality',
            municipality: dptoCode
        };

        await this.api.api(data).toPromise().then((res: { data: any[], message: string, status: boolean }) => {
            if (res.status) {
                this.municipalities = res.data;
                this.municipalities.sort((a, b) => (a.MunicipalityName > b.MunicipalityName) ? 1 : -1)
            }
        }).finally(() => {
            $('#charging').addClass('hide');
        });
    }

    public async onChangeRegion(event): Promise<void> {
        await this.getMunicipality(event);
        this.updateComputeAddress(event);
    }

    public async onChangeCountry(event): Promise<void> {
        await this.getDepartament(event);
        this.updateComputeAddress(event);
    }

    changeMerchant() {
        this.api.merchant$.subscribe(m => {
            if (m.event == 'change-merchant') {
                this.merchant = m.data;
            }

        });
    }

    onSelectedFile(e) {
        this.selectedFile = <File>e.target.files[0];
        this.fileName = this.selectedFile.name;

    }

    public isFieldValid(field: string): boolean {
        if (this.formProfile && this.formProfile.get(field)) {
            if ('email' === field) {
                return this.formProfile.get(field).errors && this.formProfile.get(field).hasError('email');
            }
            return this.formProfile.get(field).errors && this.formProfile.get(field).hasError('required');
        }
        return false;
    }

    public saveCustomer(event): void {
        this.formProfile.updateValueAndValidity();
        console.log(this.formProfile.valid);
        if (this.formProfile.valid) {
            const dpto = this.departaments.find(x => x.StateID == this.formProfile.get('depto').value).StateCode
            this.customerData = {
                typedoc: this.formProfile.get('typeDoc').value,
                identification: this.formProfile.get('numberDoc').value,
                persontype: this.formProfile.get('typeCustomer').value,
                name: this.formProfile.get('fullname').value,
                tax_regime: this.formProfile.get('fiscalRegimen').value,
                fiscal_responsibility: this.formProfile.get('responsability').value,
                email: this.formProfile.get('email').value,
                department: dpto,
                municipality: this.formProfile.get('region').value,
                country: this.formProfile.get('country').value,
                phone: this.formProfile.get('prePhone').value + this.formProfile.get('phone').value,
                address: this.computeAddress,
                term_counting: this.formProfile.get('dayCredit').value,
                idcustomer: parseInt(this.cookie.get('merchant')),
                zip_code: this.formProfile.get('zipcode').value,
                via: this.formProfile.get('viaDetails').value,
                via_type: this.formProfile.get('via').value,
                number_address: this.formProfile.get('numberBuilder').value,
                inmueble: this.formProfile.get('builderDetails').value,
                inmueble_type: this.formProfile.get('builder').value,
                floor: this.formProfile.get('floor').value,
            };
            this.onSubmit(this.customerData);
        } else {
            console.log(this.formProfile.errors)
            document.documentElement.scrollTop = document.body.scrollTop = 300;
        }

    }

    private async onSubmit(customer: ICustomer): Promise<boolean> {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'customer',
            type: 'post',
            data: customer
        };

        const savedCustomer = await this.api.api(data).toPromise().then((res: IResponseApi) => {
            this.customerData = res.data;
            if (this.returnToInvoice === true) {
                this.router.navigate(['conciliation/create-voucher/factura/' + this.customerData.id]);
            }
            return res.succes;
        }).catch((error) => {
            return false;
        });

        if (savedCustomer) {
            this.modalService.success({
                nzTitle: 'Cliente guardado',
                nzContent: '<p>Cliente guardado correctamente</p>',
                nzOnOk: () => console.log('success')
            });
        } else {
            this.modalService.error({
                nzTitle: 'Error',
                nzContent: '<p>No se Puede Guardar el Cliente Pongase en contacto con soporte</p>',
                nzOnOk: () => console.log('Error')
            });
        }

        $('#charging').addClass('hide');
        return savedCustomer;

    }

    getProfile() {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant: this.merchant,
            service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
            type: 'get-profile',
            data: ''
        }

        this.api.api(data).toPromise().then((res: any) => {

            $('#charging').addClass('hide');

            this.photoActual = res.image;

            let user = JSON.parse(this.cookie.get('ud'));
            user.userDetails.image = res.image;

            this.cookie.set('ud', JSON.stringify(user));

            if (res.is_active) {
                res.is_active = '1';
            }

            this.formProfile.setValue({
                fullname: res.fullname,
                mobile: res.mobile,
                profileId: '',
                email: res.email,
                status: res.is_active,
            });

            this.srcPhoto = res.image;


        })
            .catch(e => {
                if (e.status == 401) {
                    this.cookie.set('ud', '');
                    this.router.navigate(['/']);
                }
            });
    }

    createFormGroup() {
        return new FormGroup({
            typeDoc: new FormControl(null, [Validators.required]),
            numberDoc: new FormControl('', [Validators.required]),
            typeCustomer: new FormControl('', [Validators.required]),
            fullname: new FormControl('', [Validators.required]),
            fullnameMerchant: new FormControl(''),
            fiscalRegimen: new FormControl(''),
            responsability: new FormControl(''),
            email: new FormControl('', [Validators.email]),
            depto: new FormControl('', [Validators.required]),
            region: new FormControl('', [Validators.required]),
            country: new FormControl('', [Validators.required]),
            prePhone: new FormControl(''),
            phone: new FormControl(''),
            address: new FormControl(''),
            dayCredit: new FormControl(''),
            priceToSell: new FormControl(''),
            zipcode: new FormControl('', ),
            via: new FormControl(''),
            viaDetails: new FormControl(''),
            numberBuilder: new FormControl(''),
            builder: new FormControl(''),
            builderDetails: new FormControl(''),
            floor: new FormControl('')
        });
    }


}
