import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-voucher',
  templateUrl: './create-voucher.component.html',
  styleUrls: ['./create-voucher.component.scss']
})

export class CreateVoucherComponent implements OnInit {

  documento = this.obtenerDocumento();

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {
    
   }

  ngOnInit() {
  }

  obtenerDocumento(){
    const pathname = window.location.pathname
    if(pathname.indexOf("factura") > -1){
      return "Factura";
    }else if(pathname.indexOf("credito") > -1){
      return "Crédito";
    }else if(pathname.indexOf("debito") > -1){
      return "Débito";
    }
  }
}
