import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { IProduct } from 'src/app/interfaces/IProduct';
import { IResponseApi } from 'src/app/interfaces/IResponseApi';
import { ETypeProduct, EStatusProduct } from 'src/app/enums/ETypeProduct';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.scss']
})
export class ServiceProductComponent implements OnInit {
 
  public titlePage: string = 'Servicios / Productos > Nuevo';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public fileName: String = 'Añadir foto de perfil';
  public srcPhoto: String = '/assets/img/photo.jfif';
  public selectedFile: File = null; 
  public photoActual: String; 
  public response = false;
  public ProductService: IProduct;
  public eTypeProduct = ETypeProduct;
  public eStatusProduct = EStatusProduct;

  public radioTypeProduct: string;
  
  formProfile: FormGroup;
  constructor(private api: ApiService, private cookie: CookieService, private router: Router, private _router:  ActivatedRoute, private modalService: NzModalService) {
    this.formProfile = this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.getProfile();
    
    this.radioTypeProduct = this.eTypeProduct.service.toString();
    this.formProfile.get('type').setValue(this.eTypeProduct.service);
    this.formProfile.get('status').setValue(this.eStatusProduct.active);

    this._router.params.subscribe(params => {
      if(params.id){
        
        let data = {
          token: JSON.parse(this.cookie.get('ud')).token,
          service: 'getproduct',
          merchant: this.merchant,
          productid: params.id
        };
        
        this.api.api(data).toPromise().then((customerRes: IResponseApi) => {
          if(customerRes.succes === true){
            this.ProductService = customerRes.data;
            this.formProfile.get('sku').setValue(this.ProductService.sku);
            this.formProfile.get('name').setValue(this.ProductService.name);
            this.formProfile.get('type').setValue(this.ProductService.type);
            this.radioTypeProduct = this.ProductService.type.toString();
            this.formProfile.get('status').setValue(this.ProductService.status);
            this.formProfile.get('unit').setValue(this.ProductService.unit);
            this.formProfile.get('gravamen').setValue(this.ProductService.gravamen);
            this.formProfile.get('price1').setValue(this.ProductService.price1);
            this.formProfile.get('price2').setValue(this.ProductService.price2);
            this.formProfile.get('price3').setValue(this.ProductService.price3);
          }
        }).finally(()=>{
          $('#charging').addClass('hide');
        });
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {        
        this.merchant = m.data;
      }
      
    });
  }

  onSelectedFile(e){
    this.selectedFile = <File>e.target.files[0];    
    this.fileName = this.selectedFile.name; 
    
  }

  public onSelectedTypeProduct(event): void{
    this.formProfile.get('type').setValue(event);
  }

  public saveProduct(event): void{
    this.formProfile.updateValueAndValidity();
    if (this.formProfile.valid){
      this.ProductService = {
                            sku: this.formProfile.get('sku').value,
                            name: this.formProfile.get('name').value,
                            type: parseInt(this.formProfile.get('type').value),
                            status: parseInt(this.formProfile.get('status').value) ,
                            unit: this.formProfile.get('unit').value,
                            gravamen: this.formProfile.get('typeGravamen').value === 'Gravado'
                                ? this.formProfile.get('gravamen').value : '0',
                            price1: this.formProfile.get('price1').value ? parseFloat(this.formProfile.get('price1').value) : null,
                            price2: this.formProfile.get('price2').value ? parseFloat(this.formProfile.get('price2').value) : null,
                            price3: this.formProfile.get('price3').value ? parseFloat(this.formProfile.get('price3').value) : null,
                            userid: this.merchant
                          };
      this.onSubmit(this.ProductService);
    }else{
      document.documentElement.scrollTop = document.body.scrollTop = 300;
    }

  }

  private async onSubmit(product: IProduct): Promise<boolean>{
    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      service: 'product',
      type: 'post',
      data: product
    };

    const savedCustomer =  await this.api.api(data).toPromise().then((res: IResponseApi) => {
      this.ProductService = res.data;
      return res.succes;
    }).catch((error) => {
      return false;
    });

    if (savedCustomer){
      this.modalService.success({
        nzTitle: 'Cliente guardado',
        nzContent: '<p>Producto o servicio Guardado</p>',
        nzOnOk: () => console.log('success')
      });
    }else{
      this.modalService.error({
        nzTitle: 'Error',
        nzContent: '<p>No se Puede Guardar el Producto o servicio, Pongase en contacto con soporte</p>',
        nzOnOk: () => console.log('Error')
    });
    }

    $('#charging').addClass('hide');
    return savedCustomer;

  }

  getProfile(){
    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
      type: 'get-profile',
      data: ''
    }

    this.api.api(data).toPromise().then((res: any) => {

      $('#charging').addClass('hide');
      
      this.photoActual = res.image; 

      let user = JSON.parse(this.cookie.get('ud'));
      user.userDetails.image = res.image; 

      this.cookie.set('ud', JSON.stringify(user));

      if(res.is_active){
        res.is_active = '1';
      }
      
      this.formProfile.setValue({
        fullname: res.fullname,
        mobile: res.mobile,
        profileId: '',
        email: res.email,
        status: res.is_active,        
      });

      this.srcPhoto = res.image;

      


    })
      .catch(e => {
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  createFormGroup() {
    return new FormGroup({
      sku: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      type: new FormControl(this.eTypeProduct.product, [Validators.required]),
      status: new FormControl('', [Validators.required]),
      unit: new FormControl('', [Validators.required]),
      gravamen: new FormControl('', []),
      typeGravamen: new FormControl('', [Validators.required]),
      price1: new FormControl('', [Validators.required]),
      price2: new FormControl(''),
      price3: new FormControl('')
    });
  }



}
