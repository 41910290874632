
import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlCore = environment.api_url_cachin;


  constructor(private http: HttpClient, private cookie: CookieService, public router: Router) {

  }


  api(datos) {

    switch (datos.service) {

      case 'auth/login':
        return this.http.post(`${this.urlCore + datos.service}`, datos);
      case 'register':
        return this.http.post(`${this.urlCore + datos.service}`, datos);

      default:

        break;

    }


  }

}
