import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';


@Component({
  selector: 'app-commerce-peru',
  templateUrl: './commerce-peru.component.html',
  styleUrls: ['./commerce-peru.component.scss']
})
export class CommercePeruComponent implements OnInit {


  public titlePage: string = 'Configuraciones > Mi perfil';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public fileName: String = 'Añadir foto de perfil';
  public srcPhoto: String = '/assets/img/photo.jfif';
  public selectedFile: File = null; 
  public photoActual: String; 
  public response = false;

  formProfile: FormGroup;
  constructor(private api: ApiService, private cookie: CookieService, private router: Router) {
    this.formProfile = this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.getProfile();
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {        
        this.merchant = m.data;
      }
      
    });
  }

  onSelectedFile(e){
    this.selectedFile = <File>e.target.files[0];    
    this.fileName = this.selectedFile.name; 
    
  }

  onSubmit(){

    var file = new FormData(); 

    if (this.selectedFile) {     
      file.append('image', this.selectedFile, this.selectedFile.name);
      file.append('metadata', JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails));    
      let data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'configuration/user/uploadimg/' + this.merchant + '/' + this.userDetails.id,
        type: 'post',
        data: file
      }

      this.api.api(data).toPromise().then((res: any) => {
        $('#charging').addClass('hide');
        
        this.onSubmit2();

      })
        .catch(e => {
          $('#charging').addClass('hide');
            console.log(e);
      
        });
    }else{
     
      this.onSubmit2();
    }



  }

  onSubmit2() {
    this.response = false;

    var fd;

    fd = {
      email: this.email.value,
      fullname: '',
      mobile: this.mobile.value,
      profileId: this.profileId.value,
      status: this.status.value,
      metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
    }


    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
      type: 'post',
      data: fd
    }

    this.api.api(data).toPromise().then((res: any) => {
      $('#charging').addClass('hide');
      this.response = true;
      this.getProfile();

    })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status == 401) {
          console.log(e);
          // this.cookie.set('ud', '');
          // this.router.navigate(['/']);
        }
      });

  }



  getProfile(){
    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
      type: 'get-profile',
      data: ''
    }

    this.api.api(data).toPromise().then((res: any) => {

      $('#charging').addClass('hide');
      
      this.photoActual = res.image; 

      let user = JSON.parse(this.cookie.get('ud'));
      user.userDetails.image = res.image; 

      this.cookie.set('ud', JSON.stringify(user));

      if(res.is_active){
        res.is_active = '1'; 
      }
      
      this.formProfile.setValue({
        fullname: '',
        mobile: res.mobile,
        profileId: res.profileid,
        email: res.email,
        status: res.is_active,        
      });

      this.srcPhoto = res.image;

      


    })
      .catch(e => {
        if (e.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
  }

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      fullname: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      profileId: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    });
  }


  get email() { return this.formProfile.get('email'); }
  get fullname() { return this.formProfile.get('fullname'); }
  get mobile() { return this.formProfile.get('mobile'); }
  get profileId() { return this.formProfile.get('profileId'); }
  get status() { return this.formProfile.get('status'); }

}
